"use client";

import { Button } from "@/components/actions/Button";
import For from "@/components/common/For";
import { useNav } from "@/providers/Nav";
export default function Nav({
  subNav = []
}) {
  const {
    navRetracted
  } = useNav();
  console.log(subNav);
  return <nav className={`sticky  transition-all duration-300 ease-in-out ${navRetracted ? "top-0" : "top-[80px]"} z-[15000] bg-white py-6`} data-sentry-component="Nav" data-sentry-source-file="Nav.tsx">
      <div className="contianer mx-auto flex justify-start md:justify-center gap-8 items-center overflow-auto px-gutter">
        <For each={subNav} render={item => {
        return <Button href={`${item.value}`} variant="secondary" isLight size="small">
                {item.key}
              </Button>;
      }} data-sentry-element="For" data-sentry-source-file="Nav.tsx" />
        {/* <Button href="#stains" variant="secondary" isLight size="small">
          Stains
         </Button>
         <Button href="#paint" variant="secondary" isLight size="small">
          Paint
         </Button>
         <Button href="#laminates" variant="secondary" isLight size="small">
          Laminates
         </Button> */}
      </div>
    </nav>;
}